<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">
        {{ form.id == null ? 'New User' : 'Update User' }}
      </h2>
    </template>
    <template #action-bar>
      <div class="level mt-2">
        <div class="level-left">
          <b-button
            type="is-primary"
            outlined
            tag="router-link"
            label="Cancel"
            to="/setting/user/dashboard"
          />
          <b-button
            type="is-primary"
            native-type="submit"
            label="Confirm"
            @click="submit"
          />
        </div>
      </div>

      <hr class="mt-2 mb-0" />
    </template>
    <template #page-content>
      <h4 class="mt-0 mb-5">User Information</h4>
      <div class="columns is-multiline">
        <!-- <div class="column is-12">
          <b-field label="Is Primary *">
            <b-switch v-model="form.isPrimary"></b-switch>
          </b-field>
        </div> -->
        <div class="column is-12">
          <validation-provider
            name="Email"
            rules="required|email"
            v-slot="{ errors }"
          >
            <b-field
              label="Email"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <!-- <b-input
                v-model="form.email"
                placeholder="e.g: test@mailinator.com"
              ></b-input> -->
              {{ form.email }}
            </b-field>
          </validation-provider>
        </div>
        <div class="column is-12">
          <validation-provider name="Role" rules="required" v-slot="{ errors }">
            <b-field
              label="Role*"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <b-select placeholder="Select Role" v-model="form.roleID">
                <option v-for="role in roles" :value="role.id" :key="role.id">
                  {{ role.name }}
                </option>
              </b-select>
            </b-field>
          </validation-provider>
        </div>

        <!-- <div class="column is-12 p-0">
          <div class="column is-12">
            <validation-provider
              name="Password"
              :rules="form.id == null ? 'required' : ''"
              v-slot="{ errors }"
            >
              <b-field
                :label="form.id == null ? 'Password*' : 'Password'"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input type="password" v-model="form.password"></b-input>
              </b-field>
            </validation-provider>
          </div>
          <div class="column is-12">
            <validation-provider
              name="Password Confirmation"
              :rules="form.id == null ? 'required' : ''"
              v-slot="{ errors }"
            >
              <b-field
                :label="
                  form.id == null
                    ? 'Password Confirmation*'
                    : 'Password Confirmation'
                "
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  type="password"
                  v-model="form.passwordConfirmation"
                ></b-input>
              </b-field>
            </validation-provider>
          </div>
        </div> -->
        <div class="column is-12">
          <b-checkbox
            v-for="service in services"
            :key="service.id"
            v-model="form.services"
            :native-value="service.id"
          >
            {{ service.name }}
          </b-checkbox>
        </div>
      </div>
    </template>
  </r-page>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      form: {
        id: this.$route.params.id,
        email: '',
        password: '',
        passwordConfirmation: '',
        roleID: '',
        services: [],
      },
      roles: [
        { id: 'staff', name: 'Staff' },
        { id: 'member', name: 'Member' },
      ],
      services: [],
    }
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    ...mapActions({}),
    async submit() {
      if (this.form.id != null) {
        await this.$store
          .dispatch('user/updateUser', this.form)
          .then(() => {
            this.$swal({
              icon: 'success',
              titleText: `Success Update User`,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'button is-success',
              },
            })
            this.$router.push(`/setting/user/dashboard`)
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        await this.$store
          .dispatch('user/storeUser', this.form)
          .then(() => {
            this.$swal({
              icon: 'success',
              titleText: `Success Create User`,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'button is-success',
              },
            })
            this.$router.push(`/setting/user/dashboard`)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    async fetchUserByID(id) {
      await this.$store
        .dispatch('user/getUser', { id })
        .then((response) => {
          this.form.roleID = response.data.role
          this.form.email = response.data.user.email
          response.data.companyUserServices.forEach((service) => {
            if (service.isActive) {
              this.form.services.push(service.serviceId)
            }
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getAllServices() {
      this.$store
        .dispatch('service/getAllService')
        .then((response) => {
          this.services = response.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    this.getAllServices()
    if (this.form.id != null) {
      this.fetchUserByID(this.form.id)
    }
  },
  mounted() {},
}
</script>
